import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { valueGetter } from "./DetailsDrawer";
import { ShipmentAttachments } from "./ShipmentAttachments";
import { getEquipmentFromShipment } from "src/utils/shipment";
import { WarningAmber } from "@mui/icons-material";
import { useState } from "react";

export function ShipmentBasicInfo({ setSelectedTabIndex }) {
  const { shipment } = useShipmentDetails();
  const [open, setOpen] = useState(false);

  const getCarrierShipment = () => {
    if (shipment.mode === 'LTL') {
      const carrier = shipment.integrations?.find(integration => (integration.status == 'success'));

      if (carrier && carrier.metadata) {
        return carrier.metadata.carrier;
      } else {
        return '--';
      }
    } else {
      return 'Trinity Logistic';
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: "0.75rem",
        padding: "1rem",
      }}
    >
      {
        // This checks if shipment.services.hazardousCargoCharge is truthy. If it is, the Box component is rendered, otherwise, nothing happens
        shipment.services?.hazardousCargoCharge && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"4px"}
            color={(theme) => theme.palette.warning.dark}
          >
            <WarningAmber />
            <Typography variant="h6">This is a hazmat shipment</Typography>
          </Box>
        )
      }
      {/* BASIC INFO */}
      <Card
        variant="outlined"
        sx={{
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          component={() => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography fontWeight={600}>
                {shipment.primaryReference}
              </Typography>
              <Typography color={"GrayText"}>
                {`Created by ${shipment.created.by.firstname} ${shipment.created.by.lastname}`}
              </Typography>
            </Box>
          )}
          sx={{
            "&.MuiCardHeader-root": {
              p: 0,
            },
          }}
        />
        <Divider />
        <CardContent
          sx={{
            "&.MuiCardContent-root": {
              p: 0,
            },
          }}
        >
          {/* PO Number */}
          {shipment.poNumber && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>PO Number</Typography>
              <Typography fontWeight={600}>
                {valueGetter(shipment.poNumber)}
              </Typography>
            </Box>
          )}

          {/* Combined Primary References */}
          {shipment.combined && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>Combined From</Typography>
              <Typography fontWeight={600}>
                {shipment.combinedFrom?.map((shipment) => valueGetter(shipment.primaryReference)).join(', ')}
              </Typography>
            </Box>
          )}

          {/* Shipment Number */}
          {shipment.shipmentNumber && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>Shipment Number</Typography>
              <Typography fontWeight={600}>
                {valueGetter(shipment.shipmentNumber)}
              </Typography>
            </Box>
          )}
          {/* Order Number */}
          {shipment.ordersNumbers && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>Order Number</Typography>
              <Typography fontWeight={600}>
                {valueGetter(shipment.ordersNumbers)}
              </Typography>
            </Box>
          )}
          {/* Transfer Number */}
          {shipment.transferNumber && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>Transfer Number</Typography>
              <Typography fontWeight={600}>
                {valueGetter(shipment.transferNumber)}
              </Typography>
            </Box>
          )}

          {/* Customer */}
          {shipment.customer && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>Customer</Typography>
              <Typography fontWeight={600}>
                {valueGetter(shipment.customer)}
              </Typography>
            </Box>
          )}
          {/* COX PO NUMBER */}
          {shipment.coxPONumber && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: "0.25rem",
              }}
            >
              <Typography>Costumer PO Number</Typography>
              <Typography fontWeight={600}>
                {valueGetter(shipment.coxPONumber)}
              </Typography>
            </Box>
          )}

          {/* Mode */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography>Mode</Typography>
            <Typography fontWeight={600}>
              {valueGetter(shipment.mode)}
            </Typography>
          </Box>
          {/* Quantity */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography>Quantity</Typography>
            <Typography fontWeight={600}>
              {valueGetter(shipment.quantity)}
            </Typography>
          </Box>
          {/* Weight */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography>Weight</Typography>
            <Typography fontWeight={600}>
              {`${valueGetter(shipment.weight)}/${valueGetter(
                shipment.weightUOM,
                "lb"
              )}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography>Dimensions</Typography>
            <Tooltip title="Length X  Width X Height - Unit">
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Typography fontWeight={600}>
                  {valueGetter(shipment.length)}
                </Typography>
                <Typography color={"GrayText"}>X</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(shipment.width)}
                </Typography>
                <Typography color={"GrayText"}>X</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(shipment.height)}
                </Typography>
                <Typography fontWeight={600}>
                  {valueGetter(shipment.dimensionUOM)}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
          {/* Status */}
          {/* Equipment */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography>Equipment</Typography>
            <Typography fontWeight={600}>
              {valueGetter(getEquipmentFromShipment(shipment))}
            </Typography>
          </Box>

          {/* Carrier */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Typography>Carrier</Typography>
            <Typography fontWeight={600}>
              {valueGetter(getCarrierShipment(shipment))}
            </Typography>
          </Box>
          {
            shipment.mileage && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Mileage</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(shipment.mileage.distanceInMiles)}mi, about {valueGetter(shipment.mileage.hours)}h {valueGetter(shipment.mileage.minutes)}min
                </Typography>
              </Box>
            )
          }
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          title={"Pickup & Stop"}
          sx={{
            "&.MuiCardHeader-root": {
              p: 0,
            },
          }}
          action={
            <Tooltip title={"Open Pickup and Stop Details"}>
              <Box>
                <IconButton onClick={() => setSelectedTabIndex("1")}>
                  <ArrowRightIcon />
                </IconButton>
              </Box>
            </Tooltip>
          }
        />
      </Card>
      <Card
        variant="outlined"
        sx={{
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          title={"Packages"}
          sx={{
            "&.MuiCardHeader-root": {
              p: 0,
            },
          }}
          action={
            <Tooltip
              title={
                !!shipment.pickups[0].items?.length
                  ? "Open Items Details"
                  : "No Item on Shipment"
              }
            >
              <Box>
                <IconButton
                  disabled={!shipment.pickups[0].items?.length}
                  onClick={() => setSelectedTabIndex("2")}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Box>
            </Tooltip>
          }
        />
      </Card>
      {shipment.specialInstructions && (
        <Card
          variant="outlined"
          sx={{
            py: "0.5rem",
            px: "1rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <CardHeader
            title={"Special Instructions"}
            sx={{
              "&.MuiCardHeader-root": {
                p: 0,
              },
            }}
          />
          <Typography>{shipment.specialInstructions}</Typography>
        </Card>
      )}

      {/* ATTACHMENTS */}
      <ShipmentAttachments />
    </Box>
  );
}
