import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMask } from "@react-input/mask";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const FormInputMask = (props) => {
  const { name, selectCountry } = props;
  const [phoneValue, setPhoneValue] = useState("");
  const {control, setValue} = useFormContext()  

  const isFirstRender = useRef(true);

  const phoneMask = useMemo(() => {
    switch (selectCountry) {
      case "MX":
        return "(__) ____-____";
      case "CA":
        return "(___) ___-____";
      case "USA":
        return "(___) ___-____";
      default:
        return "(___) ___-____";
    }
  }, [selectCountry]);  

  const inputRef = useMask({ mask: phoneMask, replacement: { _: /\d/ } });  

  //Clears phone when changing country 
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else{
      setValue(name, "");
      setPhoneValue("");    
    }
  }, [selectCountry, setValue, name])

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.defaultValue ? props.defaultValue : ""}
      render={({ field }) => {
        const { onChange } = field;
        return phoneMask ? (           
            <TextField
              {...props}
              {...field}
              name={name}
              label="Phone"
              type="text"
              inputRef={inputRef}
              onChange={(event) => {
                setPhoneValue(event.target.value);
                setValue(name, event.target.value)
                onChange(event);
              }}
            />
          ) : (
            <TextField
              name={name}
              label="Phone"
              type="text"
              value={phoneValue}
              onChange={(event) => {
                setPhoneValue(event.target.value);
                setValue(name, event.target.value)
                onChange(event);
              }}
            />
          );        
      }}
    />
  );  
};
