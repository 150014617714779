import {
	Grid,
	Box,
	Typography,
	LinearProgress,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import {
	RadioButtonCheckedOutlined
} from "@mui/icons-material";
import { useShipmentQueries } from "../../hooks/useShipmentQueries";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fDateString } from "src/utils/formatTime";

export function ShipmentHistory() {

	const {
		isHistoryLoading,
		getHistoryById,
		shipmentHistory
	} = useShipmentQueries();

	const { shipmentId } = useParams();

	useEffect(() => {
		getHistoryById({ shipmentId })
	}, [])

	const theme = useTheme();

	const HISTORIES_LAST_ELEMENT_INDEX = shipmentHistory?.length - 1;

	const renderDivider = () => (
		<div style={{ width: 1, height: '100%', backgroundColor: '#CECECE' }} />
	);

	const renderItem = (item, index) => {
		return (

			<Grid key={index} container sx={{ paddingBottom: '0.5rem' }}>

				<Grid sx={{
					display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 4, [theme.breakpoints.down('sm')]: {
						display: 'none',
					}
				}} >
					<RadioButtonCheckedOutlined fontSize={"0.4rem"} color="primary" sx={{ marginBottom: 1, paddingTop: '0.2rem' }} />
					{index !== HISTORIES_LAST_ELEMENT_INDEX && renderDivider()}
				</Grid>

				<Grid>
					<Typography sx={{ color: "text.secondary" }} fontSize={"0.9rem"}>{fDateString(item.timestamp)}</Typography>

					<Typography fontWeight="600" fontSize={"0.9rem"}>{item.change}</Typography>
				</Grid>
			</Grid>
		)
	};


	const renderTrackingStatus = () => (
		shipmentHistory?.map((item, index) => (
			renderItem(item, index)
		))
	);

	const renderEmptyList = () => (
		<Typography variant="body1" sx={{ color: "text.secondary" }}>
			No shipment updates yet.
		</Typography>
	);

	return (
		<Box sx={{ padding: '1rem' }}>
			{isHistoryLoading && <LinearProgress />}
			{!isHistoryLoading && <>{shipmentHistory?.length ? renderTrackingStatus() : renderEmptyList()}</>}
		</Box>
	);
}
