import { SimCardDownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { Tooltip } from "@mui/material";
import { useShipmentDetails } from "../context/ShipmentDetailsContext";
import { useEffect } from "react";

export function ImportIntegrationFile({ 
  shipmentId, 
  loadId, 
  fileType,
  importCallback
}) {
  const {
    importIntegrationFile,
    isFileImportLoading,
    importIntegrationResponse,
  } = useShipmentQueries();
  const { setShipment } = useShipmentDetails();

  async function handleImportIntegrationFile() {
    await importIntegrationFile({ shipmentId, loadId, fileType });
  }

  useEffect(() => {
    if (importIntegrationResponse?.data) {
      console.log(
        "Has integration Data, updated Shipment:",
        importIntegrationResponse?.data
      );
      importCallback(importIntegrationResponse.data);
    }
  }, [importIntegrationResponse]);

  return (
    <Tooltip title={`Import File from integration`}>
      <LoadingButton
        loading={isFileImportLoading}
        onClick={handleImportIntegrationFile}
        type="button"
        sx={{
          minWidth: "fit-content",
          borderRadius: "0.5rem",
          boxShadow: 1,
        }}
      >
        <SimCardDownloadOutlined />
      </LoadingButton>
    </Tooltip>
  );
}
